import { render, staticRenderFns } from "./NewFilter.vue?vue&type=template&id=f1f13334&scoped=true&"
import script from "./NewFilter.vue?vue&type=script&lang=js&"
export * from "./NewFilter.vue?vue&type=script&lang=js&"
import style0 from "./NewFilter.vue?vue&type=style&index=0&id=f1f13334&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1f13334",
  null
  
)

export default component.exports