<template>
  <content-with-sidebar class="blog-wrapper">
    <!-- Filters -->
    <new-filter
      :clubs="clubs"
      :countries="countries"
      :states="states"
      :check-permission="checkPermission"
      :color-primary-btn="colorPrimaryBtn"
      @fetch-clubs-by-country="fetchClubsByCountry"
      @filter-query="handleFilters"
      @assign-location-to-filters="assignLocationToFilters"
    />
    <!-- News -->
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-card img-top>
              <b-skeleton-img
                card-img="top"
                aspect="3:1"
              />
              <b-skeleton width="85%" />
              <b-skeleton width="55%" />
              <b-skeleton width="70%" />
            </b-card>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-card img-top>
              <b-skeleton-img
                card-img="top"
                aspect="3:1"
              />
              <b-skeleton width="85%" />
              <b-skeleton width="55%" />
              <b-skeleton width="70%" />
            </b-card>
          </b-col>
        </b-row>
      </template>
    </b-skeleton-wrapper>
    <!-- Data -->
    <b-row
      v-if="notices && loading == false"
      class="blog-list-wrapper"
    >
      <b-col
        v-for="item in notices.data"
        :key="item.hash"
        md="4"
      >
        <b-card
          tag="article"
          no-body
        >
          <b-link :to="{ name: 'new-details', params: { id: item.slug } }">
            <div class="objetfit text-center">
              <b-img
                :src="item.image ? item.image.url_absolute : ''"
                alt="image OStrail"
                class="card-img-top"
              />
            </div>
          </b-link>
          <b-card-body>
            <b-card-title>
              <b-link
                :to="{ name: 'new-details', params: { id: item.slug } }"
                class="blog-title-truncate text-body-heading"
              >
                {{ item.title.length > 30 ? item.title.substring(0,30)+"..." : item.title }}
              </b-link>
            </b-card-title>
            <div
              v-if="isClub(item)"
              class="float-right d-flex"
            >
              <b-media no-body>
                <b-media-aside
                  vertical-align="center"
                  class="mr-50"
                >
                  <b-avatar
                    href="javascript:void(0)"
                    size="24"
                    :src="item.clubs ? item.clubs[0].club_image_media_file.url_thumb : ''"
                  />
                </b-media-aside>
                <b-media-body>
                  <span>{{ item.clubs[0].club_name }}</span>
                </b-media-body>
              </b-media>
              <!-- <b-card-sub-title class="ml-3"> -->
              <!-- <p>{{ item.clubs[0].club_name }}</p> -->
              <!-- </b-card-sub-title> -->
            </div>
            <div
              v-else
              class="float-right d-flex"
            >
              <b-media no-body>
                <b-media-aside
                  vertical-align="center"
                  class="mr-50"
                >
                  <b-avatar
                    href="javascript:void(0)"
                    size="24"
                    variant="light-primary"
                    src="@/assets/images/ostrail/logo.png"
                  />
                </b-media-aside>
                <b-media-body>
                  <span>{{ 'OStrail' }}</span>
                </b-media-body>
              </b-media>
            </div>
            <div class="w-100">&nbsp;</div>
            <div class="d-inline-flex">
              <b-media no-body>
                <b-media-aside
                  vertical-align="center"
                  class="mr-50"
                >
                  <b-avatar
                    v-if="item.user.avatar_file_hash != 0 && item.user.avatar_file_hash != null"
                    href="javascript:void(0)"
                    size="24"
                    :src="`${path}/${item.user.avatar_media_file.url_relative}`"
                  />
                  <b-avatar
                    v-else
                    variant="secondary"
                    :text="item.user.initials"
                  />
                </b-media-aside>
                <b-media-body>
                  <small class="text-muted mr-50">{{ $t('generic.by') }}</small>
                  <small>
                    <b-link class="text-body">{{ item.user ? item.user.full_name : '' }}</b-link>
                  </small>
                  <span class="text-muted ml-75 mr-50">|</span>
                  <small class="text-muted">{{ item.init_publication_date | formatDate }}</small>
                </b-media-body>
              </b-media>
            </div>
            <div class="mb-1 pd-tags">
              <b-link
                v-for="(tag,index) in item.tags"
                :key="index"
              >
                <b-badge
                  pill
                  class="mg-tags"
                  variant="light-primary"
                >
                  {{ tag }}
                </b-badge>
              </b-link>
              <b-badge
                v-if="checkEditAdmin(item)"
                pill
                class="mg-tags"
                :variant="statusColor(item.status)"
              >
                {{ item.status ? $t('PUBLISH') : $t('UNPUBLISHED') }}
              </b-badge>
              <b-badge
                v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
                pill
                class="mg-tags"
                :variant="statusColor(item.status)"
              >
                {{ item.status ? $t('PUBLISH') : $t('UNPUBLISHED') }}
              </b-badge>
            </div>
            <!-- eslint-disable vue/no-v-html -->
            <b-card-text
              class="blog-content-truncate"
              v-html="item.description.substring(0,80) + '...'"
            />
            <hr>
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center text-body">
                <b-link
                  v-if="checkEditAdmin(item)"
                  :to="{ name: 'new-edit', params: { id: item.slug } }"
                  class="font-weight-bold"
                >
                  {{ $t('buttons.edit') }}
                </b-link>
                <b-link
                  v-if="checkPermission(['ALLOW_ALL', 'EDIT_NOTICES'])"
                  :to="{ name: 'new-edit', params: { id: item.slug } }"
                  class="font-weight-bold"
                >
                  {{ $t('buttons.edit') }}
                </b-link>
              </div>
              <b-link
                v-if="checkPermission(['ALLOW_ALL', 'VIEW_NOTICES'])"
                :to="{ name: 'new-details', params: { id: item.slug } }"
                class="font-weight-bold"
              >
                {{ $t('generic.see') }}
              </b-link>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12">
        <app-paginator
          :data-list="notices"
          @pagination-data="getNews"
        />
      </b-col>
    </b-row>
    <!-- Sidebar -->
    <div
      slot="sidebar"
      class="blog-sidebar py-2 py-lg-0"
    >
      <!-- Input search -->
      <!-- <search-sidebar-new
        :new-list="notices.data"
        @filter-query="handleFilters"
      /> -->
      <!--/ input search -->

      <!-- recent posts -->
      <posts-sidebar-new
        :recommended-notices="recommendedNotices"
        :loading="isLoadingSidebar"
      />
      <!--/ recent posts -->
    </div>
    <!-- sidebar -->
  </content-with-sidebar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import checkPermission from '@/auth/permissions'
import clubService from '@/services/clubsService'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import NewFilter from './NewFilter.vue'
import AppPaginator from '../../components/app-paginator/AppPaginator.vue'
import PostsSidebarNew from './PostsSidebarNew.vue'

export default {
  components: {
    ContentWithSidebar,
    NewFilter,
    AppPaginator,
    PostsSidebarNew,
  },
  data() {
    return {
      clubs: [],
      recommendedNotices: [],
      managementNews: false,
      loading: false,
      isLoadingSidebar: false,
      tempQuery: {
        filtersObj: {
          // location: this.$i18n.locale,
          location: null,
        },
        paramsObj: {
          page: 1,
          perPage: 15,
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      notices: 'noticeStore/notices',
      countries: 'countries',
      states: 'states',
      clubInfo: 'clubInfo',
      colorPrimaryBtn: 'colorPrimaryBtn',
      path: 'path',
    }),
  },
  watch: {
    '$i18n.locale': function i18nlocale() {
      // this.tempQuery.filtersObj.location = this.$i18n.locale
      this.tempQuery.filtersObj.location = null
      this.fetchData()
      this.fetchDataRecommended()
    },
  },
  created() {
    if (this.checkPermission(['VIEW_NOTICES'])) {
      // this.tempQuery.filtersObj.location = this.$i18n.locale
      this.tempQuery.filtersObj.location = null
      if (!this.checkPermission(['ALLOW_ALL', 'CREATE_NOTICES'])) {
        this.tempQuery.paramsObj.active = 1
      }
      this.fetchData()
      this.fetchDataRecommended()
    } else {
      this.$router.push({ name: 'not-authorized' })
    }
  },
  methods: {
    checkPermission,
    ...mapActions({
      fetchDataNotices: 'noticeStore/fetchData',
      fetchDataRecommendedNotices: 'noticeStore/fetchDataRecommended',
      fetchCountries: 'fetchCountries',
    }),
    async fetchData() {
      this.loading = true
      await this.fetchCountries()
      await this.getNews()
    },
    async fetchDataRecommended() {
      this.isLoadingSidebar = true
      this.recommendedNotices = []
      const act = this.tempQuery.paramsObj.active
      // await this.fetchDataRecommendedNotices({ paramsObj: { limitRandom: 3, active: act }, filtersObj: { location: this.$i18n.locale } }).then(response => {
      await this.fetchDataRecommendedNotices({ paramsObj: { limitRandom: 3, active: act }, filtersObj: { location: null } }).then(response => {
        this.recommendedNotices = response.data.data
        this.isLoadingSidebar = false
      }).catch(error => {
        this.isLoadingSidebar = false
        this.responseCatch(error)
      })
    },
    handleFilters(filterSelect) {
      if (filterSelect) {
        this.tempQuery.filtersObj = filterSelect
      }
      this.getNews()
    },
    assignLocationToFilters(filterSelect) {
      if (filterSelect) {
        this.tempQuery.filtersObj = JSON.parse(JSON.stringify(filterSelect))
        // this.tempQuery.filtersObj.location = this.$i18n.locale
        this.tempQuery.filtersObj.location = null
      }
      this.getNews()
    },
    async validRouterAdmin() {
      if (this.$route.name === 'news') {
        this.managementNews = true
      } else {
        this.managementNews = false
        // this.tempQuery.filtersObj.location = this.$i18n.locale
        this.tempQuery.filtersObj.location = null
      }
    },
    async getNews(pagination) {
      this.loading = true
      if (pagination) {
        this.tempQuery.paramsObj.page = pagination.page
        this.tempQuery.paramsObj.perPage = pagination.perPage
      }
      await this.fetchDataNotices(this.tempQuery).then(() => {
        this.loading = false
      }).catch(error => {
        this.loading = false
        this.responseCatch(error)
      })
    },
    fetchClubsByCountry(pCountry, pFlag) {
      this.clubs = []

      if (pFlag) {
        const filter = {
          status: 1,
          country_hash: pCountry,
        }

        clubService.getClubs({}, filter).then(({ data }) => {
          this.clubs = data.data
        }).catch(error => {
          this.responseCatch(error)
        })
      }
    },
    checkEditAdmin(item) {
      if (this.checkPermission(['EDIT_NOTICES'])
        && (this.isClub(item))
        && !this.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])) {
        return true
      }
      return false
    },
    isClub(item) {
      if (item.show_all_clubs === false && item.country_hash == null) {
        return true
      }
      return false
    },
    statusColor(status) {
      if (status === false) return 'light-danger'
      if (status === true) return 'light-success'
      return 'light-primary'
    },
    resetForm() {
      this.notice.hash = null
      this.notice.title = null
      this.notice.tags = null
      this.notice.description = null
      this.notice.slug = null
      this.notice.content = null
      this.notice.status = false
      this.notice.location = null
      this.notice.init_publication_date = null
      this.notice.end_publication_date = null
      this.notice.share_post = false
      this.notice.head_content_type_key = null
      this.notice.head_content_url = null
      this.notice.image = null
      this.notice.image_media_file_hash = null
      this.notice.head_content = null
      this.notice.head_content_media_file_hash = null
      this.notice.club = null
      this.notice.club_hash = null
      this.notice.user = null
      this.notice.show_all_clubs = false
      this.notice.country_hash = null
      this.notice.country = null
      this.notice.owned_by = null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/pages/page-blog.scss';

.objetfit > img{
  min-width: 200px;
  height: 200px;
  object-fit: cover;
  background-color: black;
}
</style>

<style lang="css" scoped>
.mg-tags {
  margin-top: 6px;
  margin-right: 5px;
}

.pd-tags {
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
