<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>{{ $t("generic.filters") }}</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <!-- Search -->
        <b-col
          cols="12"
          md="4"
          class="mb-1"
        >
          <label>{{ $t("generic.search") }}</label>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="query.name"
              debounce="1000"
              :placeholder="$t('generic.search')"
            />
          </b-input-group>
        </b-col>
        <!-- End Search -->
        <b-col
          v-if="checkPermission(['ALLOW_ALL', 'CREATE_NOTICES'])"
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t("generic.status") }}</label>
          <v-select
            v-model="query.status"
            label="key"
            :placeholder="$t('generic.selectStatus')"
            :options="statusesOption"
            :reduce="item => item.number"
          >
            <template #option="{ key }">
              <span> {{ $t(key) }}</span>
            </template>

            <template #selected-option="{ key }">
              <span class="align-middle"> {{ $t(key) }}</span>
            </template>
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t("generic.language") }}</label>
          <v-select
            v-model="query.location"
            label="name"
            :placeholder="$t('generic.selectLanguage')"
            :options="localesOption"
            :reduce="item => item.locale"
          >
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
        </b-col>
        <b-col
          v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label for="club-country-id">{{ $t('generic.country') }}</label>
          <v-select
            id="club-country-id"
            v-model="query.country_hash"
            label="name"
            :options="countries"
            :reduce="country => country.id"
            :placeholder="$t('generic.selectCountry')"
          >
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
        </b-col>
        <!-- <b-col
          v-if="checkPermission(['ALLOW_ALL'])"
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label for="club-state-id">{{ $t('generic.state') }}</label>
          <v-select
            id="club-state-id"
            v-model="query.state_hash"
            label="name"
            :options="states"
            :disabled="query.country_hash == null"
            :reduce="city => city.id"
            :placeholder="$t('generic.selectState')"
          >
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
        </b-col> -->
        <b-col
          v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('corporate.club') }}</label>
          <v-select
            v-model="query.club_hash"
            :placeholder="$t('corporate.selectSportClub')"
            label="club_name"
            :options="clubs"
            :reduce="club => club.hash"
          >
            <!-- :disabled="query.state_hash == null" -->
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
        </b-col>
        <div class="w-100" />

        <!-- Fecha -->
        <!-- Start -->
        <b-col
          v-if="checkPermission(['ALLOW_ALL', 'CREATE_NOTICES'])"
          cols="12"
          md="4"
          lg="4"
          class="mt-1"
        >
          <label>{{ $t('generic.datePublished') }}</label>
          <b-form-datepicker
            id="datepicker-buttons"
            v-model="query.init_publication_date"
            :locale="$i18n.locale"
            :placeholder="$t('generic.initDate')"
          />
          <b-button
            v-if="query.init_publication_date"
            class="mt-1"
            size="sm"
            variant="outline-danger"
            @click="query.init_publication_date = null"
          >
            {{ $t('generic.clearDate') }}
          </b-button>
        </b-col>

        <!-- End -->
        <b-col
          v-if="checkPermission(['ALLOW_ALL', 'CREATE_NOTICES'])"
          cols="12"
          md="4"
          class="mt-1"
        >
          <label />
          <b-form-datepicker
            v-model="query.end_publication_date"
            :locale="$i18n.locale"
            :min="query.init_publication_date"
            class="form-control"
            :placeholder="$t('generic.endDate')"
          />
          <b-button
            v-if="query.end_publication_date"
            class="mt-1"
            size="sm"
            variant="outline-danger"
            @click="query.end_publication_date = null"
          >
            {{ $t('generic.clearDate') }}
          </b-button>
        </b-col>
        <b-col
          v-if="checkPermission(['ALLOW_ALL', 'CREATE_NOTICES'])"
          cols="12"
          md="4"
          class="mt-3"
        >
          <div class="">
            <div class="d-flex justify-content-end">
              <span class="mr-20"> {{ $t('generic.createNewNews') }} </span>
              <b-button
                variant="primary"
                class="btn-icon rounded-circle"
                :style="colorPrimaryBtn"
                @click="
                  $router.push({
                    name: 'new-add',
                  })
                "
              >
                <feather-icon
                  icon="PlusIcon"
                />
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { BFormDatepicker } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { locales, statusesNotice } from '@core/utils/catalogStatic'
import { mapActions } from 'vuex'

export default {
  name: 'FilterJob',
  components: {
    BFormDatepicker,
    vSelect,
  },
  props: {
    clubs: {
      type: Array,
      default: null,
    },
    countries: {
      type: Array,
      default: null,
    },
    states: {
      type: Array,
      default: null,
    },
    checkPermission: {
      type: Function,
      default: null,
    },
    colorPrimaryBtn: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      localesOption: locales,
      statusesOption: statusesNotice,
      query: {
        country_hash: null,
        // state_hash: null,
        club_hash: null,
        name: null,
        location: null,
        status: null,
        init_publication_date: null,
        end_publication_date: null,
      },
    }
  },
  watch: {
    'query.country_hash': function (pCountry, pOld) {
      if (pCountry != null) {
        if (pOld != null) {
          if (pOld != pCountry) {
            this.query.club_hash = null
            this.$emit('fetch-clubs-by-country', pCountry, true)
            this.$emit('filter-query', this.query)
          }
        } else {
          this.$emit('fetch-clubs-by-country', pCountry, true)
          this.$emit('filter-query', this.query)
        }
      } else {
        this.query.club_hash = null
        this.$emit('fetch-clubs-by-country', pCountry, false)
        this.$emit('filter-query', this.query)
      }
    },
    'query.club_hash': function queryClubHash() {
      this.$emit('filter-query', this.query)
    },
    'query.status': function queryStatus() {
      this.$emit('filter-query', this.query)
    },
    'query.name': function querySearch() {
      this.$emit('filter-query', this.query)
    },
    'query.location': function queryLocale(loc) {
      if (loc) {
        this.query.location = loc
        this.$emit('filter-query', this.query)
      } else {
        this.$emit('assign-location-to-filters', this.query)
      }
    },
    'query.init_publication_date': function queryInitDate() {
      this.$emit('filter-query', this.query)
    },
    'query.end_publication_date': function queryEndDate() {
      this.$emit('filter-query', this.query)
    },
  },
  mounted() {
    //
  },
  methods: {
    ...mapActions({
      fetchStateByCountryId: 'fetchStateByCountryId',
    }),
    clearDate() {
      this.query.end_publication_date = null
    },
  },
}
</script>
<style lang="scss" scoped>
.mr-20 {
  margin-top: 10px;
  margin-right: 20px !important;
}
</style>
