export const locales = [
  {
    locale: 'es',
    img: require('@/assets/images/flags/fr.png'),
    name: 'Español',
  },
  {
    locale: 'en',
    img: require('@/assets/images/flags/en.png'),
    name: 'English',
  },
  /* {
      locale: 'fr',
      img: require('@/assets/images/flags/fr.png'),
      name: 'French',
    },
    {
      locale: 'de',
      img: require('@/assets/images/flags/de.png'),
      name: 'German',
    },
    {
      locale: 'pt',
      img: require('@/assets/images/flags/pt.png'),
      name: 'Portuguese',
    }, */
]

export const statusesNotice = [
  { value: true, number: '1', key: 'PUBLISH' },
  { value: false, number: '0', key: 'UNPUBLISHED' },
]

export const contentNotice = [
  { value: '1', key: 'FILE' },
  { value: '0', key: 'URL' },
]

export const test = {}
